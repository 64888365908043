import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Icons from "../../assets/icons";
import Button from "../../components/FormComponents/Button";
import { navigation } from "../../utils/constants/app constant";

const { FlyPoolLogo, activeDashboardIcon } = Icons;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuthHeader = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const getCurrentLocation = () => location.pathname.replaceAll("-", " ").replace("/", "").split("/").shift();
 


  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <AiOutlineCloseCircle color="#ffff" size={30} />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={FlyPoolLogo.default}
                        alt="CarPool"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        {getCurrentLocation() && (
                          <li>
                            <div
                              className={classNames(
                                "text-black hover:text-white hover:bg-[#e6e1e1c4]",
                                "group flex gap-x-3 p-2 py-3 pl-4 text-sm leading-6 font-semibold rounded-3xl capitalize items-center bg-white"
                              )}
                            >
                              <img
                                src={activeDashboardIcon.default}
                                alt="FlyPool"
                              />

                              {getCurrentLocation()}
                            </div>
                          </li>
                        )}
                        <li>
                          <ul role="list" className="mx-2 space-y-2">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    "text-white hover:text-white hover:bg-[#e6e1e1c4]",
                                    "group flex gap-x-3 p-2 py-3 pl-4 leading-6 font-semibold rounded-3xl capitalize items-center "
                                  )}
                                >
                                  {item.icon({
                                    color: "#ffff",
                                  })}

                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div>
          <div className="sticky top-0 z-40 flex h-24 shrink-0 items-center gap-x-4 bg-primary px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            {/* <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <BiMenu color="#ffff" size={30} />
            </button> */}

            {/* Separator */}
            <div
              className="h-10 w-px bg-gray-900/10 lg:hidden py-5"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-10 lg:px-10 px-4">
              <div className="relative flex flex-1 items-center">
                <img className="h-14" src={FlyPoolLogo.default} alt="FlyPool" />
              </div>

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button
                  type="button"
                  className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">View notifications</span>
                  {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
                </button>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                  aria-hidden="true"
                />

                <div className="flex">
                  <div className="text-base px-6 py-2 cursor-pointer" onClick={() => navigate("/")} >
                    <span className="text-white uppercase">Login</span>
                  </div>
                  {/* <Button onClick={() => navigate("/sign-up")} variant="info">Sign Up</Button> */}
                  <Button  variant="info">Sign Up</Button>
                </div>
              </div>
            </div>
          </div>

          <main className="bg-primary">
            <div className="px-4 sm:px-6 lg:px-10 py-10 bg-white rounded-tl-md ">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default AuthHeader;
