import {
  UserManagementIcon,
  UserIcon,
  FlightManagementIcon,
  RouteManagementIcon,
  DashboardIcon,
  UserRoleManagementIcon,
  BookingManagementIcon,
  HistoricalLogsIcon,
  MenuSettingsIcon,
} from "./iconComponents";

const Icons = {
  UserManagementIcon,
  UserIcon,
  FlightManagementIcon,
  RouteManagementIcon,
  DashboardIcon,
  UserRoleManagementIcon,
  BookingManagementIcon,
  HistoricalLogsIcon,
  MenuSettingsIcon,
  FlyPoolLogo: require("./flypoolLogo.svg"),
  CarPoolLogo: require("./carpool-logo.jpg"),
  FlyPoolLogo_2: require("./flypoolLogo2.svg"),
  activeDashboardIcon: require("./activeDashboardIcon.svg"),
  dayIcon: require("./dayIcon.svg"),
  messageIcon: require("./messageIcon.svg"),
  searchIcon: require("./searchIcon.svg"),
  verticalDotsIcon: require("./verticalDotsIcon.svg"),
  downArrayIcon: require("./downArrowIcons.svg"),
  userNameIcon: require("./userNameIcon.svg"),
  seatICon: require("./seatIcon.svg"),
  ruleIcon: require("./ruleIcon.svg"),
  meetingPointIcon: require("./meetingPoint.svg"),
  luggageIcon: require("./luggageIcon.svg"),
  chatMessageIcon: require("./chatMessageIcon.svg"),
  notifyIcon: require("./notifyIcon.svg"),
  femaleIcon: require("./femaleIcon.svg"),
  maleIcon: require("./maleIcon.svg"),
};
export default Icons;
